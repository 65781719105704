import React, {useMemo, useState} from "react";
import {TAB_NAME_CHINESE_ENUM, TAB_NAME_ENUM} from "../../constants";
import ListLoading from "./Loading/ListLoading";
import SubTabLoading from "./Loading/SubTabLoading";
import {widthGTAMd} from "../../utils";
import {useTitle, useUpdateEffect, useViewport} from "../../hooks";
import {useSettingListShow} from "../../hooks/setting";
import MobileRender from "./MobileRender";
import PcRender from "./PcRender";

function Item({allTabs, activeTabKey, onChangeTab, userSetting}) {
    const widthGTAMd1 = widthGTAMd(useViewport())
    const listShowType = useSettingListShow(userSetting)
    useTitle(TAB_NAME_CHINESE_ENUM[activeTabKey])

    const subObjSessionKey = `sub_obj1`
    const [privateSubObj, setPrivateSubObj] = useState(JSON.parse(sessionStorage.getItem(subObjSessionKey)) || {})
    useUpdateEffect(() => {
        sessionStorage.setItem(subObjSessionKey, JSON.stringify(privateSubObj))
    }, [privateSubObj])
    useUpdateEffect(() => {
        setPrivateSubObj({})
        sessionStorage.removeItem(subObjSessionKey)
    }, [activeTabKey])

    const PcRenderMemo = useMemo(() => <PcRender
        allTabs={allTabs}
        activeTabKey={activeTabKey}
        listShowType={listShowType}
        setSubObj={setPrivateSubObj}
        subObj={privateSubObj}
        allComponent={TAB_NAME_TO_COMPONENT}
        defaultComponent={DEFAULT_COMPONENT}
    />, [listShowType, allTabs, activeTabKey, privateSubObj]);

    const MobileRenderMemo = useMemo(() => <MobileRender
        allTabs={allTabs}
        listShowType={listShowType}
        onChangeTab={onChangeTab}
        setSubObj={setPrivateSubObj}
        subObj={privateSubObj}
        allComponent={TAB_NAME_TO_COMPONENT}
        defaultComponent={DEFAULT_COMPONENT}
    />, [listShowType, allTabs]);

    return (
        <>
            {
                allTabs.length > 0 && activeTabKey !== "" && allTabs.some(obj => obj.hasOwnProperty("key") && obj["key"] === activeTabKey)
                    ? <>{widthGTAMd1 ? PcRenderMemo : MobileRenderMemo}</>
                    : <><SubTabLoading/><ListLoading/></>
            }
        </>
    );
}

function lazyWithRetry(fn, retriesLeft = 3, interval = 500) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    lazyWithRetry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}


const TAB_NAME_TO_COMPONENT = {
    [TAB_NAME_ENUM.BAIDU_TIEBA]: React.lazy(() => lazyWithRetry(() => import('./BaiduTieba'))),
    [TAB_NAME_ENUM.XIAOHONGSHU]: React.lazy(() => lazyWithRetry(() => import('./Xiaohongshu'))),
    [TAB_NAME_ENUM.DOUBAN_COMMUNITY]: React.lazy(() => lazyWithRetry(() => import('./DoubanCommunity'))),
    [TAB_NAME_ENUM.DOUBAN_MEDIA]: React.lazy(() => lazyWithRetry(() => import('./DoubanMedia'))),
    [TAB_NAME_ENUM.HUXIU]: React.lazy(() => lazyWithRetry(() => import('./Huxiu'))),
    [TAB_NAME_ENUM.APPRCN]: React.lazy(() => lazyWithRetry(() => import('./Apprcn'))),
    [TAB_NAME_ENUM.APPINN]: React.lazy(() => lazyWithRetry(() => import('./Appinn'))),
    [TAB_NAME_ENUM.ZHIBO8]: React.lazy(() => lazyWithRetry(() => import('./Zhibo8'))),
    [TAB_NAME_ENUM.TOP_USER]: React.lazy(() => lazyWithRetry(() => import('./TopUser'))),
    [TAB_NAME_ENUM.TOP]: React.lazy(() => lazyWithRetry(() => import('./Top'))),
    [TAB_NAME_ENUM.Ithome]: React.lazy(() => lazyWithRetry(() => import('./Ithome'))),
    [TAB_NAME_ENUM.Pojie52]: React.lazy(() => lazyWithRetry(() => import('./Pojie52'))),
    [TAB_NAME_ENUM.Tianya]: React.lazy(() => lazyWithRetry(() => import('./Tianya'))),
    [TAB_NAME_ENUM.KR36]: React.lazy(() => lazyWithRetry(() => import('./Kr36'))),
    [TAB_NAME_ENUM.BAIDU]: React.lazy(() => lazyWithRetry(() => import('./Baidu'))),
    [TAB_NAME_ENUM.SSPAI]: React.lazy(() => lazyWithRetry(() => import('./Sspai'))),
    [TAB_NAME_ENUM.HUPU]: React.lazy(() => lazyWithRetry(() => import('./Hupu'))),
    [TAB_NAME_ENUM.JOURNAL_TECH]: React.lazy(() => lazyWithRetry(() => import('./JournalTech'))),
    [TAB_NAME_ENUM.DOUYIN]: React.lazy(() => lazyWithRetry(() => import('./Douyin'))),
    [TAB_NAME_ENUM.BILIBILI]: React.lazy(() => lazyWithRetry(() => import('./Bilibili'))),
    [TAB_NAME_ENUM.GITHUB]: React.lazy(() => lazyWithRetry(() => import('./Github'))),
    [TAB_NAME_ENUM.ZHIHU]: React.lazy(() => lazyWithRetry(() => import('./Zhihu'))),
    [TAB_NAME_ENUM.WEIBO]: React.lazy(() => lazyWithRetry(() => import('./Weibo'))),
    [TAB_NAME_ENUM.V2EX]: React.lazy(() => lazyWithRetry(() => import('./V2ex'))),
    [TAB_NAME_ENUM.THEPAPER]: React.lazy(() => lazyWithRetry(() => import('./Thepaper'))),
    [TAB_NAME_ENUM.LANDIAN]: React.lazy(() => lazyWithRetry(() => import('./Landian'))),
    [TAB_NAME_ENUM.JUEJIN]: React.lazy(() => lazyWithRetry(() => import('./Juejin'))),
    [TAB_NAME_ENUM.SINA_FIN]: React.lazy(() => lazyWithRetry(() => import('./SinaFin'))),
    [TAB_NAME_ENUM.ACFUN]: React.lazy(() => lazyWithRetry(() => import('./AcFun'))),
    [TAB_NAME_ENUM.XUEQIU]: React.lazy(() => lazyWithRetry(() => import('./Xueqiu'))),
    [TAB_NAME_ENUM.NGA]: React.lazy(() => lazyWithRetry(() => import('./Nga'))),
    [TAB_NAME_ENUM.GAMERSKY]: React.lazy(() => lazyWithRetry(() => import('./GamerSky'))),
    [TAB_NAME_ENUM.IFANR]: React.lazy(() => lazyWithRetry(() => import('./Ifanr'))),
    [TAB_NAME_ENUM.EASTMONEY]: React.lazy(() => lazyWithRetry(() => import('./EastMoney'))),
    [TAB_NAME_ENUM.PENTI]: React.lazy(() => lazyWithRetry(() => import('./Penti'))),
    [TAB_NAME_ENUM.GUANCHA_USER]: React.lazy(() => lazyWithRetry(() => import('./GuanchaUser'))),
    [TAB_NAME_ENUM.SHADIAO_NEWS]: React.lazy(() => lazyWithRetry(() => import('./ShadiaoNews'))),
    [TAB_NAME_ENUM.NE_NEWS]: React.lazy(() => lazyWithRetry(() => import('./NeNews'))),
    [TAB_NAME_ENUM.TOUTIAO]: React.lazy(() => lazyWithRetry(() => import('./Toutiao'))),
    [TAB_NAME_ENUM.SMZDM]: React.lazy(() => lazyWithRetry(() => import('./Smzdm'))),
    [TAB_NAME_ENUM.TOUTIAOIO]: React.lazy(() => lazyWithRetry(() => import('./Toutiaoio'))),
    [TAB_NAME_ENUM.KDS]: React.lazy(() => lazyWithRetry(() => import('./Kds'))),
    [TAB_NAME_ENUM.INFO_Q]: React.lazy(() => lazyWithRetry(() => import('./InfoQ'))),
    [TAB_NAME_ENUM.DIYICAIJING]: React.lazy(() => lazyWithRetry(() => import('./Diyicaijing'))),
    [TAB_NAME_ENUM.GELONGHUI]: React.lazy(() => lazyWithRetry(() => import('./Gelonghui'))),
    [TAB_NAME_ENUM.CSDN]: React.lazy(() => lazyWithRetry(() => import('./Csdn'))),
    [TAB_NAME_ENUM.GUOKR]: React.lazy(() => lazyWithRetry(() => import('./Guokr'))),
    [TAB_NAME_ENUM.KUAISHOU]: React.lazy(() => lazyWithRetry(() => import('./Kuaishou'))),
    [TAB_NAME_ENUM.WEREAD]: React.lazy(() => lazyWithRetry(() => import('./Weread'))),
    [TAB_NAME_ENUM.ZHIHU_DAILY]: React.lazy(() => lazyWithRetry(() => import('./ZhihuDaily'))),
    [TAB_NAME_ENUM.TENCENT_NEWS]: React.lazy(() => lazyWithRetry(() => import('./TencentNews'))),
    [TAB_NAME_ENUM.XMYP]: React.lazy(() => lazyWithRetry(() => import('./Xmyp'))),
}
const DEFAULT_COMPONENT = React.lazy(() => lazyWithRetry(() => import('./Default')))

export default Item;
