export const TAB_NAME_ENUM = {
    V2EX: "v2ex",
    ZHIHU: "zhihu",
    WEIBO: "weibo",
    BILIBILI: "bilibili",
    GITHUB: "github",
    DOUYIN: "douyin",
    JOURNAL_TECH: "journal-tech",
    HUPU: "hupu",
    SSPAI: "sspai",
    BAIDU: "baidu",
    KR36: "36kr",
    Tianya: "tianya",
    Pojie52: "52pojie",
    Ithome: "ithome",
    TOP: "top",
    TOP_USER: "top-user",
    TOP_DAYLONG: "top-daylong",
    ZHIBO8: "zhibo8",
    APPINN: "appinn",
    APPRCN: "apprcn",
    HUXIU: "huxiu",
    DOUBAN_MEDIA: "douban-media",
    DOUBAN_COMMUNITY: "douban-community",
    XIAOHONGSHU: "xiaohongshu",
    BAIDU_TIEBA: "baidu-tieba",
    THEPAPER: "thepaper",
    LANDIAN: "landian",
    JUEJIN: "juejin",
    SINA_FIN: "sina-fin",
    ACFUN: "acfun",
    XUEQIU: "xueqiu",
    NGA: "nga",
    GAMERSKY: "gamersky",
    IFANR: "ifanr",
    EASTMONEY: "eastmoney",
    PENTI: "penti",
    GUANCHA_USER: "guancha-user",
    SHADIAO_NEWS: "shadiao-news",
    NE_NEWS: "ne-news",
    TOUTIAO: "toutiao",
    SMZDM: "smzdm",
    TOUTIAOIO: "toutiaoio",
    KDS: "kds",
    INFO_Q: "infoq",
    DIYICAIJING: "diyicaijing",
    GELONGHUI: "gelonghui",
    CSDN: "csdn",
    GUOKR: "guokr",
    KUAISHOU: "kuaishou",
    WEREAD: "weread",
    ZHIHU_DAILY: "zhihu-daily",
    TENCENT_NEWS: "tencent-news",
    XMYP: "xmyp",
}

export const TAB_NAME_CHINESE_ENUM = {
    [TAB_NAME_ENUM.V2EX]: "V2EX",
    [TAB_NAME_ENUM.ZHIHU]: "知乎",
    [TAB_NAME_ENUM.WEIBO]: "微博",
    [TAB_NAME_ENUM.BILIBILI]: "哔哩哔哩",
    [TAB_NAME_ENUM.GITHUB]: "GitHub",
    [TAB_NAME_ENUM.DOUYIN]: "抖音",
    [TAB_NAME_ENUM.JOURNAL_TECH]: "技术期刊",
    [TAB_NAME_ENUM.HUPU]: "虎扑",
    [TAB_NAME_ENUM.SSPAI]: "少数派",
    [TAB_NAME_ENUM.BAIDU]: "百度",
    [TAB_NAME_ENUM.KR36]: "36氪",
    [TAB_NAME_ENUM.Tianya]: "天涯",
    [TAB_NAME_ENUM.Pojie52]: "吾爱破解",
    [TAB_NAME_ENUM.Ithome]: "IT之家",
    [TAB_NAME_ENUM.TOP]: "全站热榜",
    [TAB_NAME_ENUM.TOP_USER]: "订阅热榜",
    [TAB_NAME_ENUM.TOP_DAYLONG]: "全站24小时",
    [TAB_NAME_ENUM.ZHIBO8]: "直播吧",
    [TAB_NAME_ENUM.APPINN]: "小众软件",
    [TAB_NAME_ENUM.APPRCN]: "反斗限免",
    [TAB_NAME_ENUM.HUXIU]: "虎嗅",
    [TAB_NAME_ENUM.DOUBAN_MEDIA]: "豆瓣书影音",
    [TAB_NAME_ENUM.DOUBAN_COMMUNITY]: "豆瓣社区",
    [TAB_NAME_ENUM.XIAOHONGSHU]: "小红书",
    [TAB_NAME_ENUM.BAIDU_TIEBA]: "百度贴吧",
    [TAB_NAME_ENUM.THEPAPER]: "澎湃新闻",
    [TAB_NAME_ENUM.LANDIAN]: "蓝点网",
    [TAB_NAME_ENUM.JUEJIN]: "掘金",
    [TAB_NAME_ENUM.SINA_FIN]: "新浪财经",
    [TAB_NAME_ENUM.ACFUN]: "AcFun",
    [TAB_NAME_ENUM.XUEQIU]: "雪球",
    [TAB_NAME_ENUM.NGA]: "NGA社区",
    [TAB_NAME_ENUM.GAMERSKY]: "游民星空",
    [TAB_NAME_ENUM.IFANR]: "爱范儿",
    [TAB_NAME_ENUM.EASTMONEY]: "东方财富",
    [TAB_NAME_ENUM.PENTI]: "喷嚏网",
    [TAB_NAME_ENUM.GUANCHA_USER]: "观风闻",
    [TAB_NAME_ENUM.SHADIAO_NEWS]: "沙雕新闻",
    [TAB_NAME_ENUM.NE_NEWS]: "网易新闻",
    [TAB_NAME_ENUM.TOUTIAO]: "今日头条",
    [TAB_NAME_ENUM.SMZDM]: "什么值得买",
    [TAB_NAME_ENUM.TOUTIAOIO]: "开发者头条",
    [TAB_NAME_ENUM.KDS]: "宽带山",
    [TAB_NAME_ENUM.INFO_Q]: "InfoQ",
    [TAB_NAME_ENUM.DIYICAIJING]: "第一财经",
    [TAB_NAME_ENUM.GELONGHUI]: "格隆汇",
    [TAB_NAME_ENUM.CSDN]: "CSDN",
    [TAB_NAME_ENUM.GUOKR]: "果壳",
    [TAB_NAME_ENUM.KUAISHOU]: "快手",
    [TAB_NAME_ENUM.WEREAD]: "微信读书",
    [TAB_NAME_ENUM.ZHIHU_DAILY]: "知乎日报",
    [TAB_NAME_ENUM.TENCENT_NEWS]: "腾讯新闻",
    [TAB_NAME_ENUM.XMYP]: "小米有品",
}

export const BREAKPOINT_WIDTH_ENUM = {
    SM: 640,
    MD: 768,
    ASIDE_SIZE: 886, // 低于这个，将不显示侧边栏
    LG: 1024,
    XL: 1280,
}